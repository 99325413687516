<template>
  <div class="startups">
    <h1>Startups</h1>
    <CompanyCard v-for="company in companies" :key="company.id" :company="company" />
  </div>
</template>

<script>
// @ is an alias to /src
import CompanyCard from '@/components/CompanyCard.vue'
import CompanyService from '@/services/CompanyService.js'

export default {
  name: 'Startups',
  components: {
    CompanyCard
  },
  data() {
    return {
      companies: null
    }
  },
  created() {
    CompanyService.getCompanies()
    .then(response => {
      this.companies = response.data
    })
    .catch(error => {
      console.log(error)
    })
  }
}
</script>

<style scoped>
.startups {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>