<template>
<router-link :to="{ name: 'StartupDetails', params: { id: company.id } }">
  <div class="company-card">
    <Card style="width: 25em">
    <template #header>
        <img alt="user header" src="https://primefaces.org/primevue/showcase/demo/images/usercard.png">
    </template>
    <template #title>
        {{company.title}}
    </template>
    <template subtitle>
        {{company.id}}
    </template>
    <template #content>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
        quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
    </template>
    <template #footer>
        <Button icon="pi pi-check" label="Save" />
        <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em" />
    </template>
    </Card>
  </div>
</router-link>
</template>

<script>

export default {
  name: 'CompanyCard',
  props: {
    company: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
