<template>
  <div class="startup" v-if="company">
    <h1>Startup Details</h1>
    <CompanyCard :company="company" />
  </div>
</template>

<script>
import CompanyCard from '@/components/CompanyCard.vue'
import CompanyService from '@/services/CompanyService.js'

export default {
  name: 'Startups',
  components: {
    CompanyCard
  },
  props: ['id'],
  data() {
    return {
      company: null
    }
  },
  created() {
    // fetch company (by id) and set local data
    CompanyService.getCompany(this.id)
    .then(response => {
      this.company = response.data
    })
    .catch(error => {
      console.log(error)
    })
  }
}
</script>
